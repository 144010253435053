import React from 'react';
import { graphql } from 'gatsby';
import Layout, { SiteContext } from '../Layout';
import FlexibleContentLoop from '../FlexibleContentLoop';
import BannerContentLoop from '../BannerContentLoop';
import Dochead from '../Dochead';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';

const Page = ({
  data: {
    page: {
      title,
      slug: { current },
      _rawFlexibleContent,
      _rawBanners,
      _rawMainImage,
    },
  },
}) => (
  <Layout>
    <SiteContext.Consumer>
      {(value) => (
        <>
          <Dochead
            title={current === '/' ? null : title}
            description={value.siteDescription}
            siteName={value.siteTitle}
            pageUrl={`/${current}`}
            pageImage={
              _rawMainImage.asset
                ? imageUrlFor(buildImageObj(_rawMainImage))
                    .width(600)
                    .quality(100)
                    .url()
                : imageUrlFor(buildImageObj(value.siteImage))
                    .width(600)
                    .quality(100)
                    .url()
            }
          />
          <BannerContentLoop
            pageTitle={title}
            mainImage={_rawMainImage}
            contentArray={_rawBanners}
          />
          <FlexibleContentLoop contentArray={_rawFlexibleContent} />
        </>
      )}
    </SiteContext.Consumer>
  </Layout>
);

export default Page;

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(_id: { eq: $id }) {
      title
      slug {
        current
      }
      _rawMainImage(resolveReferences: { maxDepth: 10 })
      _rawBanners(resolveReferences: { maxDepth: 10 })
      _rawFlexibleContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
